.App {
  text-align: center;
}

.background {
  background-color: black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes navColor {
  from {color: #339999;}
  to {color: #EEEEEE;}
  from {background-color: #EEEEEE44;}
  to {background-color: #002222;}
}

a:link {
  color: #3399FF;
}

a:visited {
  color: #9999FF;
}

a.navlink {
  color: #339999;
  font-size: 1.16rem;
  text-decoration: none;
  padding: 1rem;
  border-radius: 2.5rem;
}

.navlink:hover {
  background-image: radial-gradient(circle, #EEEEEE33, #00222211);
  animation-name: navColor;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

nav {
  background-color: #002222;
  padding: 1.25rem;
  text-align: center;
  margin-left: 0%;
  margin-right: 30%;
  border-radius: 2.5rem;
  position: fixed;
  top: 0%;
  z-index: 1;
}

.banner {
  background-color: #002222;
  background-image: radial-gradient(circle, #00222233, #EEEEEE22);
  color: #339999;
  text-align: center;
  margin-top: 3.1rem;
  margin-bottom: 3.1rem;
  margin-left: 30%;
  margin-right: 30%;
  padding: 0.6rem;
  border-radius: 3rem;
  box-shadow: 0 0 1.1rem 0 #003333;
  position: absolute;
  top: 1.2rem;
  left: 0%;
  right: 0%;
}

@media (max-width: 640px) and (min-resolution: 120dpi){
  .banner{
    margin-left: 10%;
    margin-right: 10%;
  }
}

.pagecontainers {
  background-color: #002222BB;
  background-image: radial-gradient(circle, #00222233, #EEEEEE22);
  color: #339999;
  text-align: center;
  margin: 4rem;
  padding: 0.8rem;
  border-radius: 3rem;
  box-shadow: 0 0 1.1rem 0 #003333;
  position: absolute;
  top: 16.5rem;
  left: 0%;
  right: 0%;
}

.skills {
  display: flex;
  flex-flow: justify;
}

.all-skills {
  padding-left: 7%;
  padding-right: 7%;
  margin: 2rem;
  border-radius: 12rem;
  border: 4px solid #33999922;
  box-shadow: 0 0 1.1rem 0 #003333;
}


.frontpage {
  background-color: #002222BB;
  background-image: radial-gradient(circle, #00222233, #EEEEEE22);
  color: #339999;
  margin: 3rem;
  padding: 0.6rem;
  border-radius: 3rem;
  box-shadow: 0 0 1.1rem 0 #003333;
  position: absolute;
  top: 16.5rem;
  left: 0%;
  right: 0%;
}

span {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px){
  .all-skills {
    padding-left: 3%;
    padding-right: 3%;
    margin: 2rem;
    border-radius: 12rem;
    border: 4px solid #33999922;
    box-shadow: 0 0 1.1rem 0 #003333;
  }
}

@media (max-width: 640px) and (min-resolution: 120dpi){
  #root {
    display: block;
    align-items: center;
  }

  nav {
    width: 90%;
    display: block;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  a.navlink {
    font-size: 1.16rem;
  }

  .banner {
    top: 2.5rem;
    display: block;
    align-items: center;
  }

  .frontpage{
    top: 22rem;
  }

  .pagecontainers {
    top: 22rem;
  }

  .skills {
    display: block;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sitepic {
    /* height: auto;
    width: auto; */
    height: 200px;
    width: 200px;
    /* max-width: 100%; */
    /* padding-left: auto; */
    /* padding-right: auto; */
    padding-top: 5%;
    padding-bottom: 5%;
    /* z-index: 1; */
  }

  .pic-container {
    max-width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .all-skills {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20%;
    padding-bottom: 20%;
    margin: 0;
    border-radius: 12rem;
    display: block;
  }
}

@media (max-width: 360px){
  a.navlink {
    font-size: 1rem;
  }
}

@media (max-width: 320px){
  a.navlink {
    font-size: 0.82rem;
  }
}

.projectcontainers {
  background-color: #002222BB;
  background-image: linear-gradient(to right, #EEEEEE22, #00252533, #EEEEEE22);
  color: #339999;
  text-align: center;
  margin: 3rem;
  padding: 0.6rem;
  border-radius: 3rem;
  box-shadow: 0 0 1.1rem 0 #003333;
  position: absolute;
  top: 16.5rem;
  left: 0%;
  right: 0%;
}

.projects {
  display: grid;
  grid-template-columns: 31rem 31rem;
  grid-auto-rows: auto;
  justify-content: center;
  grid-column-gap: 1.2rem;
}

.linklogos {
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
}

.demopics {
  width: 23rem;
  height: 14.375rem;
}

@media (max-width: 768px){
  .projectcontainers {
    display: block;
    margin: 0;
    padding: 0;
    top: 22rem;
  }

  .projects {
    display: block;
  }

  .demopics {
    max-width: 11.5rem;
    max-height: 7.1875rem;
  }
}

@keyframes icons {
  from {
      transform:rotate(0deg);
      opacity: 1
  }
  to {
      transform:rotate(720deg);
      opacity: 0.5
  }
}

.linklogos:hover {
  animation-name: icons;
  animation-duration: 1.5s;
  opacity: 0.5
}

.demopics {
  width: 23rem;
  height: 14.375rem;
}

.project {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 1.43rem;
  padding: 1.43rem;
  background-color: #000100;
  box-shadow: 0 0 1.1rem 0 #002525;
  left: 0%;
  right: 0%;
}

.projects-info {
  margin-left: 10%;
  margin-right: 10%;
}

@media (max-width: 640px) and (min-resolution: 120dpi){
  .project {
    align-items: center;
    padding: 1.43rem;
    background-color: #000100;
    box-shadow: 0 0 1.1rem 0 #002525;
  }
}

.info {
  background-color: #DDFFFF;
  color: #002222;
  border-radius: 0.4rem;
  font-family: 'arial'
}

.canvas {
  position: fixed;
  top: 0%;
  z-index: -1;
}

.sitepic {
  height: 200px;
  width: 200px;
  /* padding-left: 6.25rem;
  padding-right: 6.25rem; */
}

.footnote {
  color: #002222;
  bottom: 0%;
}

.contact-form {
  display: block;
}

.contact-items {
  padding: 1rem;
  margin: 1rem;
}

.message-input {
  height: 5rem;
  rows:"5";
}

.disclaimer {
  font-size: 6px;
}
